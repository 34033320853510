import React from 'react';
import './App.css';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  
  const { isAuthenticated, user, loginWithRedirect, logout, isLoading } = useAuth0();

  const LoggedOutSection = () => {
    return (
      <div>
        <button onClick={() => loginWithRedirect()}>Login</button>
      </div>
    );
  }

  const LoggedInSection = () => {

    var identities:any[] = user['http://csnsw-cloud/identities'];
    const identityList = identities.map(i => {
      if (i.connection === 'database'){
            return (
              <div key={i.user_id}>
                database...
              </div>
            );
      }
      else{
        return (
          <div key={i.user_id}>
            <p>Identity type: {i.provider}</p>
          </div>
        );
      }
    });

    const metadata = user['http://csnsw-cloud/app_meta_data'];
    const userMetadata = metadata?.organisation ? Object.keys(metadata.organisation).map((k: any) => {
      return (
        <li key={k}>{k}: {metadata.organisation[k]}</li>
      );
    }) : null;

    console.log('metadata',metadata);

    return(
      <div>
        <div>
          <p>Hi <b>{user.name}</b></p>
          <p>Email: {user.email}</p>
          <p>Total number of identities: {user['http://csnsw-cloud/identity_count']}</p>
          <p>Metadata - organisation:</p>
          <ul>{userMetadata}</ul>
          <p>Metadata - roles:</p>
          <ul>
            <li key='roleid'>Id: {metadata.role.id}</li>
            <li key='roledesc'>Id: {metadata.role.value}</li>
          </ul>
        </div>
        {identityList}
        <div>
          <button className="logout" onClick={() => logout({returnTo: window.location.origin})}>Logout</button>
        </div>
      </div>
    );
  }

  const getContent = () => {
    if (isLoading) return (
      <div>
          <p>Loading...</p>
      </div>
    );

    if (isAuthenticated)
      return <LoggedInSection />
    
    return <LoggedOutSection />;    

  };

  if(user) console.log(user);

  return (
    <div className="app">
      <p className="app-header">CSNSW Auth0 Test Application</p>
      <p className="app-sub-header">Environment: {process.env.REACT_APP_MY_APP_TITLE}</p>
      {getContent()}
    </div>
  );
}

export default App;
